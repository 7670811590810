import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import HomeImage from "../images/laravel-dev-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import TimelineV1Component from "../component/TimelineV1Component";
import Icon from "../images/card-icon.png";
import CardsComponent from "../component/CardsComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";

const ServiceLaravelDev = () => {
  const seo = {
    title: "Outsource Laravel Development | Motomtech",
    metaDesc:
      "Expert outsource Laravel development teams available to build your next project, or augment your current development team.",
  };

  const cards = [
    {
      icon: Icon,
      title: "IoT Development with Laravel:",
      description:
        "Leverage the power of the Internet of Things (IoT) with our Laravel-based solutions. We deliver IoT applications that are robust, scalable, and secure, helping you drive innovation and efficiency in your operations.",
    },
    {
      icon: Icon,
      title: "Custom Laravel Development:",
      description:
        "Our custom Laravel development solutions are tailor-made to address your unique business requirements. From custom web applications to API development, we ensure that your Laravel project is aligned with your strategic goals.",
    },
    {
      icon: Icon,
      title: "CRM Development with Laravel: ",
      description:
        "Streamline your customer relationship management with our Laravel CRM solutions. We develop CRM systems that are efficient, intuitive, and capable of driving customer engagement and retention.",
    },
    {
      icon: Icon,
      title: "Custom Laravel CMS:",
      description:
        "Manage your content more effectively with our custom Laravel CMS solutions. We build content management systems that are user-friendly, flexible, and offer advanced features for superior content management.",
    },
    {
      icon: Icon,
      title: "Laravel Enterprise Applications:",
      description:
        "Our Laravel enterprise solutions are designed to handle the complexities and challenges of large-scale business operations. We deliver enterprise applications that are scalable, reliable, and capable of driving business efficiency.",
    },
    {
      icon: Icon,
      title: "Laravel for eCommerce:",
      description:
        "Tap into the booming eCommerce market with our Laravel eCommerce solutions. We develop eCommerce platforms that offer seamless shopping experiences, secure payment gateways, and advanced features to drive sales and customer satisfaction.",
    },
    {
      icon: Icon,
      title: "Laravel for Startups:",
      description:
        "Startups can significantly benefit from our Laravel solutions. We deliver cost-effective, scalable, and innovative Laravel solutions that help startups launch their products quickly and efficiently.",
    },
    {
      icon: Icon,
      title: "Laravel Microservices Architecture:",
      description:
        " Our Laravel microservices architecture solutions enable you to build applications that are scalable, resilient, and easy to manage. This architecture style helps to break down your application into smaller, independent services that can be developed, deployed, and scaled independently.",
    },
  ];

  const questions1 = [
    {
      title: "What makes Laravel stand out from other PHP frameworks?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Laravel stands out from other PHP frameworks due to its elegant
            syntax, extensive set of features, and emphasis on developer
            productivity. Laravel offers a clean and simple API, allowing for
            rapid application development, which is an important factor in the
            fast-paced world of web development. Its expressive, elegant syntax
            makes coding enjoyable for developers and results in a cleaner, more
            maintainable codebase.
          </p>
          <p className="accordion-section__card-desc">
            One of the most notable features of Laravel is its MVC
            (Model-View-Controller) architecture, which promotes better
            organization of code and separation of concerns, making it easier
            for developers to work on complex projects. Furthermore, Laravel is
            renowned for its robust ecosystem, which includes pre-built packages
            for various tasks, a powerful ORM (Object-Relational Mapping), queue
            management, and a command-line interface called Artisan, among
            others. Its built-in security features, such as protection against
            SQL injection, cross-site scripting, and cross-site request forgery,
            further bolster its credibility.
          </p>
        </>
      ),
      eventKey: 1,
    },
  ];

  const questions2 = [
    {
      title: "How long does it take to create applications with Laravel?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            The time required to create an application with Laravel can vary
            significantly based on the complexity of the application, the
            specific requirements, and the expertise of the development team.
            Laravel is known for its efficiency and rapid development
            capabilities, which can expedite the development process. However, a
            simple application may take a few weeks, while a more complex
            application could take several months.
          </p>
          <p className="accordion-section__card-desc">
            At Motomtech, we focus on delivering quality solutions in a timely
            manner. Our experienced Laravel developers utilize proven
            methodologies and best practices to ensure the project is completed
            within the agreed timeframe. In the initial stages of the project,
            we work closely with you to understand your requirements, establish
            a development timeline, and set milestones to ensure that the
            project stays on track. Rest assured, our priority is always to
            deliver a high-quality, robust Laravel application that meets your
            business needs, within the designated timeline.
          </p>
        </>
      ),
      eventKey: 2,
    },
  ];

  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={"Outsource Laravel Development Services"}
        homeDescription={
          "Complete your next Laravel project faster and at a lower cost with Motomtech’s Laravel development outsourcing services for enterprises and startups alike."
        }
        homeImage={HomeImage}
        homeButton={"Request our services"}
        isGatsby={false}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={
          "Outsource your Laravel Needs With Fully Customizable Laravel Development Services"
        }
        description1={
          "Choosing Motomtech for your Laravel development needs is not only a strategic decision but also a game-changer for your business. We understand that every project comes with unique requirements, and as such, it deserves a tailored solution. Our team at Motomtech consists of highly skilled and experienced Laravel developers who have honed their skills through countless successful projects, providing innovative and robust solutions for our clients. The choice to outsource Laravel development to us ensures that your projects are handled with utmost proficiency and dedication."
        }
        description2={
          "Outsourcing Laravel development to Motomtech can be the right choice for a multitude of reasons. Laravel is a sophisticated framework that requires a specific skill set for seamless integration and utilization. Our team of experts is not just adept at this, but they're also continually innovating and adapting to the evolving digital landscape. By outsourcing to us, you gain access to a pool of talents who are at the forefront of Laravel development, resulting in quality output that saves time, cuts down costs, and streamlines your operations."
        }
        description3={
          "Another compelling reason to choose us is our breadth of experience. Laravel development is our forte. Our experience spans across various industry verticals, empowering us to understand your business needs better and deliver solutions that align with your strategic goals. Our portfolio speaks for our ability to handle projects of any scale and complexity. With a client-centric approach, we ensure that your vision is translated into a reality, providing solutions that are not only functional but also deliver an outstanding user experience."
        }
        description4={
          "Trust is a significant factor when outsourcing Laravel development, and at Motomtech, we take this seriously. Our commitment to transparency, integrity, and open communication throughout the project cycle sets us apart. We understand the importance of your project, and as such, we keep you involved at every stage, ensuring that our work aligns with your expectations and that the end product is a true reflection of your business requirements."
        }
        description5={
          "In conclusion, choosing Motomtech for your Laravel development needs guarantees top-notch solutions tailored to your unique requirements, access to expert Laravel developers, and the peace of mind that comes with entrusting your project to a reliable and experienced team."
        }
      />
      <TimelineV1Component
        timelineTitle={"Laravel Development Services"}
        timelineTextTitle1={"Custom Laravel Development:"}
        timelineTextDescription1={
          "At Motomtech, we specialize in developing tailor-made Laravel applications that cater to your unique business needs. We design and create high-performing, scalable applications that deliver a competitive edge, focusing on user experience and seamless functionality."
        }
        timelineTextTitle2={"Laravel IT Augmentation Services: "}
        timelineTextDescription2={
          "Our Laravel IT augmentation services offer the flexibility to extend your existing team with our skilled Laravel developers. Whether you need help with a single project or require ongoing support, our experts integrate seamlessly into your team, contributing their Laravel expertise to help achieve your goals."
        }
        timelineTextTitle3={"Laravel Quality Assurance Services:"}
        timelineTextDescription3={
          "Ensuring the quality of your Laravel application is paramount to us. Our Laravel QA services involve rigorous testing methodologies to identify and fix potential issues early in the development cycle. From functionality and performance testing to security audits, we ensure your Laravel applications meet the highest standards."
        }
        timelineTextTitle4={"Laravel Maintenance and Support:"}
        timelineTextDescription4={
          "Our Laravel maintenance and support services are designed to ensure the smooth running of your applications post-deployment. We provide regular updates, feature enhancements, bug fixes, and performance optimization services to keep your Laravel application up-to-date and efficient."
        }
        timelineTextTitle5={"Laravel Consulting Services:"}
        timelineTextDescription5={
          "If you're unsure about the best way to leverage Laravel for your business, our Laravel consulting services are here to help. Our experts analyze your project requirements, guide you through best practices, and provide strategic insights to effectively utilize Laravel for your project's success."
        }
      />
      <ItExpandTeamComponent
        title={"Why Leverage Laravel For Your Next Project?"}
        description1={
          "Leveraging Laravel for your next project can significantly contribute to its success, thanks to its impressive array of features, scalability, and robustness. Laravel, being the most popular PHP framework, is the first choice of many developers and businesses alike. Its MVC architecture ensures clarity between logic and presentation, which in turn makes the development process more efficient, straightforward, and clean."
        }
        description2={
          "Ease of use is a significant reason why Laravel is preferred over other programming languages. Laravel comes with a clean, simple API that allows for rapid application development. This means that the development process is faster, which not only saves time but also reduces the costs associated with it. Its elegant syntax further simplifies the development process, enabling developers to focus more on the creative aspect of building applications rather than being tangled in the intricacies of the code."
        }
        description3={
          "In terms of flexibility, Laravel is quite versatile. It comes with numerous pre-built functions and structures, allowing developers to build everything from small websites to large-scale enterprise applications. It also supports multiple databases, giving developers the liberty to select the most suitable one based on the project requirements. Moreover, Laravel is highly scalable, ensuring that your application can grow as your business expands, thereby protecting your investment in the long run."
        }
        description4={
          "Security is a critical aspect in today's digital age, and Laravel shines in this regard. It offers robust security features out of the box, including protection from SQL injection, cross-site scripting, and cross-site request forgery. This makes Laravel a highly secure option for building applications that deal with sensitive data."
        }
        description5={
          "Lastly, Laravel boasts an active and supportive community, which is a great resource when it comes to solving challenges or staying updated with the latest trends and updates in Laravel. This ensures that Laravel continues to evolve and stay relevant, providing you with a future-proof solution for your projects."
        }
        description6={
          "In conclusion, Laravel's popularity is well-deserved, given its ease of use, flexibility, robust security, scalability, and a supportive community. Leveraging Laravel for your next project could be a decision that propels your business towards its digital goals more efficiently and effectively."
        }
      />
      <CardsComponent
        title={"Outsourced Laravel Development Solutions"}
        description={
          "Motomtech offers a wide range of outsourced Laravel development solutions that cater to various business needs. Whether you are a startup seeking to launch a new product or an established business looking to expand your digital footprint, we have the experience, knowledge, and skill to help you achieve your objectives. The solutions we offer are not just about coding; they are about delivering results that drive business growth."
        }
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <TechStackComponent />
      </div>

      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={"Frequently asked questions"}
        subtitle={"Here you can find some questions that our user ask te most."}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Jumpstart your startup with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceLaravelDev;
